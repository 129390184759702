import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Settings from "./pages/Settings";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          login: "Login",
          loginPage: "Login Into Your Account",
          username: "Email",
          pass: "Password",
          email: "Email",
          errorMessage: "Invalid Email or Password",
          errorMessageField: "Please fill all fields",
          forgetPass: "Forget your password?",
          rememberMe: "Remember me",
          noAccount: "Don't have an account?",
          haveAccount: "Already have an account?",
          register: "Register",
          newPassword: "New Password",
          confirmPassword: "Confirm Password",
          logout: "Logout",
          dashboard: "Dashboard",
          dragAndDrop: "Click to browse or Drag and drop",
          startRecording: "Start recording here",
          next: "Next",
          previous: "Previous",
          library: "Library",
          feedbacks: "Feedbacks",
          recording: "Recording...",
          date: "Date",
          search: "Search",
          customers: "Customers",
          editCustomer: "Edit Customer",
          profile: "Profile",
          AccountDetails: "Account Details",
          contacts: "Contacts",
          edit: "Edit",
          upload: "Upload",
          deleteCustomer: "Delete Customer",
          modalMessage: "Are you sure about delete this?",
          delete: "Delete",
          cancel: "Cancel",
          handleSubmit: "Submit",
          actions: "Actions",
          category: "Category",
          backToHome: "Back to Main Page",
          notFoundMsg: "Page Not Found!",
          selectPicture: "Select a picture",
          chooseName: "Choose a name",
          drums: "Drums",
          guitar: "Guitar",
          piano: "Piano",
          chooseInstrument: "Choose an instrument",
          instrument: "Instrument",
          name: "Name",
          validate: "Validate",
          title: "Title",
          reportABug: "Report a bug",
          profilePicture: "Profile Picture",
          lang: "Language",
          theme: "Theme",
          settings: "Settings",
          installAppMessage: "Autone can also be available offline. Just click on the button below, then start using it.",
          install: "Install",
          soundcloud: "Enter SoundCloud link...",
          convert: "Convert",
          deleteAccount: "Delete Account",
          filterInstrument: "Filter by Instrument ",
          moveTo: "Move to",
          rename: "Rename",
          moveFolder: "Move this folder to another location ?",
          createFolder: "Create Folder",
          folderName: "Folder Name",
          newName: "New Name",
          accountUpdated: "Account updated successfully",
          accountUpdatedMessage: "The account information has been successfully updated. Changes have been saved.",
          accountNotUpdated: "Account not updated",
          accountNotUpdatedMessage: "The account information has not been updated. Please try again later.",
          success: "Success",
          resetLinkSuccess: "A reset link has been sent to your email address. Please check your inbox and follow the instructions to reset your account password.",
          internalServerError: "Internal Server Error",
          passWordUpdatedMessage: "The password has been successfully updated. Please login with your new password.",
          failedRequestMessage: "An error occurred while processing the request. Please try again later.",
          accountCreated: "Account Created",
          accountCreatedMessage: "Congratulations! Your account has been successfully created.",
          musicLoaded: "Music loaded successfully",
          musicLoadedMessage: "The music has been successfully loaded. Changes have been saved.",
          musicNotLoaded: "Music not loaded",
          musicNotLoadedMessage: "The music has not been loaded. Please try another link.",
          recordSaved: "Record Saved",
          recordSavedMessage: "Your music record has been successfully saved.",
          userDeleted: "User Deleted",
          userDeletedMessage: "The user has been successfully deleted from the system.",
          songDeleted: "Song Deleted",
          songDeletedMessage: "The song has been successfully deleted.",
          accountDeleted: "Account Deleted",
          accountDeletedMessage: "Your account has been successfully deleted from the system.",
        },
      },
      fr: {
        translation: {
          login: "Se connecter",
          loginPage: "Se connecter à votre compte",
          username: "Email",
          pass: "Mot de passe",
          email: "Email",
          settings: "Paramètres",
          errorMessage: "Email  ou mot de passe invalide",
          errorMessageField: "Veuillez remplir tous les champs",
          forgetPass: "Oublié votre mot de passe ?",
          rememberMe: "Se souvenir de moi",
          noAccount: "Vous n'avez pas de compte ?",
          haveAccount: "Vous avez déjà un compte ?",
          register: "S'inscrire",
          newPassword: "Nouveau mot de passe",
          confirmPassword: "Confirmer le mot de passe",
          logout: "Déconnexion",
          dashboard: "Tableau de bord",
          dragAndDrop: "Cliquez pour parcourir ou Glisser-déposer",
          startRecording: "Commencer l'enregistrement ici",
          next: "Suivant",
          previous: "Précédent",
          library: "Librairie",
          feedbacks: "Retours",
          recording: "Enregistrement...",
          date: "Date",
          search: "Rechercher",
          customers: "Clients",
          editCustomer: "Modifier le client",
          profile: "Profile",
          profilePicture: "Photo de profil",
          AccountDetails: "Détails du compte",
          contacts: "Contacts",
          edit: "Modifier",
          upload: "Mettre à jour",
          deleteCustomer: "Supprimer le client",
          modalMessage: "Êtes-vous sûr de vouloir supprimer ceci ?",
          delete: "Supprimer",
          cancel: "Annuler",
          handleSubmit: "Soumettre",
          actions: "Actions",
          category: "Catégorie",
          backToHome: "Retour à la page principale",
          notFoundMsg: "Page non trouvée !",
          selectPicture: "Sélectionner une image",
          chooseName: "Choisir un nom",
          drums: "Batterie",
          guitar: "Guitare",
          piano: "Piano",
          chooseInstrument: "Choisir un instrument",
          instrument: "Instrument",
          name: "Nom",
          validate: "Valider",
          title: "Titre",
          reportABug: "Signaler un bug",
          lang: "Langue",
          theme: "Thème",
          installAppMessage: "Autone peut également être disponible hors ligne. Cliquez simplement sur le bouton ci-dessous, puis commencez à l'utiliser.",
          install: "Installer",
          soundcloud: "Entrez un lien SoundCloud...",
          convert: "Convertir",
          deleteAccount: "Supprimer le compte",
          filterInstrument: "Filtrer par instrument ",
          moveTo: "Déplacer vers",
          rename: "Renommer",
          moveFolder: "Déplacer ce dossier dans un autre emplacement ?",
          createFolder: "Créer un dossier",
          folderName: "Nom du dossier",
          newName: "Nouveau nom",
          accountUpdated: "Compte mis à jour avec succès",
          accountUpdatedMessage: "Les informations du compte ont été mises à jour avec succès. Les modifications ont été enregistrées.",
          accountNotUpdated: "Compte non mis à jour",
          accountNotUpdatedMessage: "Les informations du compte n'ont pas été mises à jour. Veuillez réessayer plus tard.",
          success: "Succès",
          resetLinkSuccess: "Un lien de réinitialisation a été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception et suivre les instructions pour réinitialiser le mot de passe de votre compte.",
          internalServerError: "Erreur interne du serveur",
          passWordUpdatedMessage: "Le mot de passe a été mis à jour avec succès. Veuillez vous connecter avec votre nouveau mot de passe.",
          failedRequestMessage: "Une erreur s'est produite lors du traitement de la demande. Veuillez réessayer plus tard.",
          accountCreated: "Compte créé",
          accountCreatedMessage: "Félicitations ! Votre compte a été créé avec succès.",
          musicLoaded: "Musique chargée avec succès",
          musicLoadedMessage: "La musique a été chargée avec succès. Les modifications ont été enregistrées.",
          musicNotLoaded: "Musique non chargée",
          musicNotLoadedMessage: "La musique n'a pas été chargée. Veuillez essayer un autre lien.",
          recordSaved: "Enregistrement enregistré",
          recordSavedMessage: "Votre enregistrement musical a été enregistré avec succès.",
          userDeleted: "Utilisateur supprimé",
          userDeletedMessage: "L'utilisateur a été supprimé avec succès du système.",
          songDeleted: "Chanson supprimée",
          songDeletedMessage: "La chanson a été supprimée avec succès.",
          accountDeleted: "Compte supprimé",
          accountDeletedMessage: "Votre compte a été supprimé avec succès du système.",
        },
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;